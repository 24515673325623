import React, { useEffect } from "react"
import type { ComponentType } from "react"

export function withActiveFilterInViewV2(Component): ComponentType {
    return (props) => {
        useEffect(() => {
            const ensureActiveElementInView = () => {
                const filterComponent = document.querySelector(
                    '[data-framer-name="Filter"]'
                )
                const selectedElement = filterComponent.querySelector(
                    '[data-framer-name="Selected State"]'
                )

                if (filterComponent && selectedElement) {
                    selectedElement.scrollIntoView({
                        behavior: "auto",
                        block: "nearest",
                        inline: "start",
                    })
                }
            }

            window.addEventListener("scroll", ensureActiveElementInView)
            return () => {
                window.removeEventListener("scroll", ensureActiveElementInView)
            }
        }, [])

        return <Component {...props} />
    }
}
